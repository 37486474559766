var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isPrivate ? _c('v-container', [_c('v-card', {
    staticClass: "pa-16",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "justify-center",
    attrs: {
      "for": "qna-validate"
    }
  }, [_c('label', {
    staticClass: "text-center",
    attrs: {
      "for": "qna-validate"
    }
  }, [_vm._v(" Only writer of this content "), _c('br', {
    staticClass: "b-lg-none"
  }), _vm._v(" can continue to read ")])]), _c('v-card-actions', {
    staticClass: "justify-center pt-0"
  }, [_c('v-btn', {
    staticClass: "px-20",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Back to the LIST")])], 1)], 1)], 1) : !_vm.authorized ? _c('v-container', [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "justify-center",
    attrs: {
      "for": "qna-validate"
    }
  }, [_c('label', {
    staticClass: "text-center",
    attrs: {
      "for": "qna-validate"
    }
  }, [_vm._v("To read the posting "), _c('br', {
    staticClass: "b-lg-none"
  }), _vm._v("Please input password")])]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "input-inline-button"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.password,
      expression: "filter.password"
    }],
    staticClass: "input",
    attrs: {
      "maxlength": "6",
      "type": "password",
      "id": "qna-validate"
    },
    domProps: {
      "value": _vm.filter.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filter, "password", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "button button--primary",
    on: {
      "click": _vm.search
    }
  }, [_vm._v("확인")])])])], 1)], 1) : _c(_vm.SkinComponent, _vm._b({
    tag: "component",
    on: {
      "remove": _vm.remove
    }
  }, 'component', {
    code: _vm.code,
    board: _vm.board,
    isUserWriter: _vm.isUserWriter
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }