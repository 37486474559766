<template>
    <v-container v-if="!writable">
        <v-card tile outlined elevation="0" class="pa-4">
            <v-card-title for="qna-validate" class="justify-center">
                <label for="qna-validate" class="text-center">You are not approved <br class="b-lg-none" />to write on this board</label>
            </v-card-title>
            <v-card-actions class="justify-center pt-0">
                <v-btn outlined @click="$router.go(-1)" class="px-20">Back to the LIST</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
    <v-container v-else-if="isPrivate">
        <v-card tile outlined elevation="0" class="pa-4">
            <v-card-title for="qna-validate" class="justify-center">
                <label for="qna-validate" class="text-center">To read the posting <br class="b-lg-none" />Please input password</label>
            </v-card-title>
            <v-card-text class="text-center">
                <div class="input-inline-button">
                    <input v-model="filter.password" maxlength="6" type="password" id="qna-validate" class="input" />
                    <button @click="search" class="button button--primary">확인</button>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
    <v-container v-else-if="!authorized">
        <v-card tile outlined elevation="0" class="pa-4">
            <v-card-title for="qna-validate" class="justify-center">
                <label for="qna-validate" class="text-center">To read the posting <br class="b-lg-none" />Please input password</label>
            </v-card-title>
            <v-card-text class="text-center">
                <div class="input-inline-button">
                    <input v-model="filter.password" maxlength="6" type="password" id="qna-validate" class="input" />
                    <button @click="search" class="button button--primary">확인</button>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
    <component v-else :is="SkinComponent" v-model="board" :code="code" @save="validate" />
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
        login: { type: Boolean, default: null },
        secret: { type: Boolean, default: null },
        _lecture: { type: String, default: undefined },
        writeScope: { type: Array, default: null },
        isPrivateInput: { type: Boolean, default: false },

        _id: String,

        scope: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            filter: {
                code: this.$props.code,
                password: null,
            },

            isPrivate: false,
            authorized: true,
            board: {
                code: this.$props.code,

                subject: null,
                content: null,
                summary: null,

                password: null,
                thumb: null,

                writer: {
                    name: null,
                },

                _files: [],
                files: [],

                meta: {
                    _lecture: this.$props._lecture,
                    isPrivate: this.$props.isPrivateInput,
                },
            },
        };
    },

    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.$store.state.payload?.scope || [];
        },
        writable() {
            return this.writeScope ? (this.writeScope || []).some((scope) => this.scope.includes(scope)) : true;
        },
    },

    mounted() {
        this.init();
    },

    watch: {
        _id() {
            this.init();
        },
    },

    methods: {
        init() {
            try {
                if (this.login && !this.accessToken) throw new Error("You must log in before writing");
            } catch (error) {
                console.error(error);
                alert(error.message);

                this.$router.go(-1);
                return;
            }

            if (this._id) this.search();
        },

        async search() {
            try {
                var { board, isPrivate, authorized } = await api.v1.boards.get({
                    _id: this.$props._id,
                    params: {
                        ...this.filter,
                        password: this.filter.password ? CryptoAES.encrypt(this.filter.password) : undefined,
                    },
                });

                this.board = board;
                this.isPrivate = isPrivate;
                this.authorized = authorized;
            } catch (error) {
                this.authorized = error.response.data?.authorized ?? false;
            }
        },

        validate() {
            try {
                if (this.secret) {
                    if (this.board.password == null || this.board.password.length !== 6) throw new Error("비밀번호는 6자리 숫자여야 합니다.");
                }
                this.save();
            } catch (error) {
                this.handleError(error);
            }
        },

        async save() {
            try {
                let { password, reply, _files = [], files = [], ...input } = this.board;
                password = this.secret ? CryptoAES.encrypt(password) : undefined;
                const isCreate = !input?._id;
                const { post, put } = api.v1.boards;
                if (this.board) var { board } = await (isCreate ? post({ ...input, password }) : put({ ...input, password }));
                if (this.board.thumb) await api.v1.boards.postThumb(board, this.board.thumb);

                const _filesToRemove = _files.filter((_file) => !files.some(({ _id }) => _id == _file));
                if (_filesToRemove) await Promise.all(_filesToRemove.map(async (_id) => await api.v1.boards.files.delete({ _id, _board: board?._id })));
                files = await Promise.all(files.map(async (file, index) => (file instanceof File ? (await api.v1.boards.files.post({ _board: board._id, index }, file))?.file : file)));
                _files = files.map(({ _id }) => _id);
                [{ board }] = [await put({ _id: board._id, _files })];

                alert("저장되었습니다");
                this.$router.go(-1);
            } catch (error) {
                this.handleError(error);
            }
        },

        handleError(error) {
            console.error(error);
            alert(error.repsonse ? error.response.data.message : error.message);
        },
    },
};
</script>
